export const sistemainstitucionalarchivos_item = [
        {
        "sistemainstitucionalarchivos_item_nombre" : "Programa Anual de Desarrollo Archivístico (PADA) 2025 CECULTAH",
        "sistemainstitucionalarchivos_item_descripcion" : "Objetivo General: Cumplir con la preservación de los documentos en arcdhivos actualizados, así como el funcionamiento de SIA para fomentar una nueva cultura archivística en el CECULTAH",
        "sistemainstitucionalarchivos_item_pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA//dir5/2025/transparencia/PADA-2025.pdf"
    },
        {
        "sistemainstitucionalarchivos_item_nombre" : "Informe Anual de Desarrollo Archivístico 2024",
        "sistemainstitucionalarchivos_item_pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA//2024/transparencia/SIA/Informe Anual de Desarrollo Archivistico 2024.pdf"
    },
    {
        "sistemainstitucionalarchivos_item_nombre" : "Programa Anual de Desarrollo Archivístico (PADA) 2023 CECULTAH",
        
        "sistemainstitucionalarchivos_item_pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/SIA/ProgramaAnualDesarrolloArchivistico2023.pdf"
    },
    {
        "sistemainstitucionalarchivos_item_nombre" : "Plan Anual de Desarrollo 2022",
        "sistemainstitucionalarchivos_item_pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/SIA/InformeAnualDesarrolloArchivistico2022.pdf"
    },
    {
        "sistemainstitucionalarchivos_item_nombre" : "Cuadro General de Clasificación Archivística del Poder Ejecutivo - CECULTAH 2020",
        "sistemainstitucionalarchivos_item_pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/SIA/CUADRO GENERAL DE CLASIFICACIÓN ARCHIVÍSTICA DEL PODER EJECUTIVO- CECULTAH 2020.pdf"
    },
    {
        "sistemainstitucionalarchivos_item_nombre" : "Catálogo de Disposición Documental CECULTAH 2019",
        "sistemainstitucionalarchivos_item_pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/SIA/CATÁLOGO DE DISPOSICIÓN DOCUMENTAL CECULTAH 2019.pdf"
    },
    ,
    {
        "sistemainstitucionalarchivos_item_nombre" : "Informe Anual de Desarrollo Archivístico 2023",
        "sistemainstitucionalarchivos_item_pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/SIA/INFORME ANUAL DE DESARROLLO ARCHIVISTICO 2023.pdf"
    },
    ,
    {
        "sistemainstitucionalarchivos_item_nombre" : "Programa Anual de Desarrollo Archivístico 2024 (PADA 2024)",
        "sistemainstitucionalarchivos_item_pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/SIA/Programa Anual de Desarrollo Archivistico PADA 2024.pdf"
    },
    {
        "sistemainstitucionalarchivos_item_nombre" : "Inventario Documental 2013",
        "sistemainstitucionalarchivos_item_pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/SIA/Inventario_Documental_2013.rar"
    },    
]