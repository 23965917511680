import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './paaas.css';

const Paaas = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container paaas-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='paaas_title'>
                    <h1>Programa Anual de Adquisiciones, Arrendamientos y Servicios del Sector Publico del CECULTAH</h1>
                </div>
            </div>
        </div>

        <div className='row'>

       
          <div class="accordion" id="accordionExample1">
            <div class="shadow mt-4 card-item-licitacion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOnePaaas2025a"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2025a" aria-expanded="false" aria-controls="collapseOnePaaas2025a">PAAAS 2025</button></h2>
              <div id="collapseOnePaaas2025a" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2024a" data-bs-parent="#accordionExample1">
                 <div class="accordion-body">
                    <ul class="list-group">
                    <li class="list-group-item">PAAAS | 2025<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA//dir5/2025/procedimientos/PAAAS_2025.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary-dos-pdf">.pdf</i></a></li>  
                    </ul>
                 </div>
              </div>
            </div>
           
        </div>
        </div>
        </div>

       <div className='row'>



          <div class="accordion" id="accordionExample1">
            <div class="shadow mt-4 card-item-licitacion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOnePaaas2024a"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2024a" aria-expanded="false" aria-controls="collapseOnePaaas2024a">PAAAS 2024</button></h2>
              <div id="collapseOnePaaas2024a" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2024a" data-bs-parent="#accordionExample1">
                 <div class="accordion-body">
                    <ul class="list-group">
                    <li class="list-group-item">PAAAS | 2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/procedimientos/CECULTAHPAAAS2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary-dos-pdf">.pdf</i></a></li>  
                    </ul>
                 </div>
              </div>
            </div>
           
        </div>
        </div>
        </div>

          <div class="accordion" id="accordionExample">
          <div class="shadow mt-4 card-item-licitacion">
            <div class="accordion-item">
              <h1 class="accordion-header" id="headingOnePaaas2023"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023" aria-expanded="false" aria-controls="collapseOnePaaas2023">PAAAS 2023</button></h1>
              <div id="collapseOnePaaas2023" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">PAAAS | 2023<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/paaas/CecultahPaaas2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary-dos-pdf">.pdf</i></a></li>
                       
                    </ul>
                 </div>
              </div>
           </div> 
           </div> 
                    

           <div className="espacio"></div>           

        <div className='row'>

          <div class="accordion" id="accordionExample1">
            <div class="shadow mt-4 card-item-licitacion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOnePaaas2023a"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023a" aria-expanded="false" aria-controls="collapseOnePaaas2023a">PAAAS 2022</button></h2>
              <div id="collapseOnePaaas2023a" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023a" data-bs-parent="#accordionExample1">
                 <div class="accordion-body">
                    <ul class="list-group">
                    <li class="list-group-item">PAAAS | 2022<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/procedimientos/CecultahPaaas2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary-dos-pdf">.pdf</i></a></li>  
                    </ul>
                 </div>
              </div>
            </div>
           
        </div>
        </div>
        </div>




          

        </div>
    </div>
  )
}

export default Paaas